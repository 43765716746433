import $ from "jquery";

class Ball {
  private shape: JQuery<HTMLElement>;
  private speed: number;
  private vx: number;
  private vy: number;
  private w: number;
  private h: number;
  private x: number;
  private y: number;
  private radius = 60;
  private container = "#header";

  constructor(url: string) {
    this.shape = $(`<img src="${url}" alt="icon" class="icon" />`);
    this.speed = this.getRandomInt(10, 15);
    this.vx = Math.random() * this.speed - Math.random() * this.speed;
    this.vy = Math.random() * this.speed - Math.random() * this.speed;
    this.w = $(this.container).width();
    this.h = $(this.container).height();
    this.x = (this.w - this.radius) / 2;
    this.y = (this.h - this.radius) / 2;

    $(window).on("resize", this.resize.bind(this));

    this.render();
  }

  getRandomInt(min: number, max: number) {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  render() {
    $(this.shape).css({
      width: this.radius,
      height: this.radius,
    });
    $(this.container).append(this.shape);
  }

  resize() {
    this.w = $(this.container).width();
    this.h = $(this.container).height();
  }

  move() {
    this.x = this.x + this.vx;
    this.y = this.y + this.vy;

    if (this.x < 0 || this.x > this.w - this.radius) {
      this.vx = -this.vx;
    }
    if (this.y < 0 || this.y > this.h - this.radius - 10) {
      this.vy = -this.vy;
    }

    this.shape.css({
      left: this.x,
      top: this.y,
      transform: `rotate(${this.y}deg)`,
    });
  }
}

class Header {
  private particles: Ball[] = [];

  constructor() {
    headerIcons.forEach((iconUrl) => this.particles.push(new Ball(iconUrl)));
  }

  render() {
    if (window.innerWidth <= 768) return;

    this.particles.forEach((particle) => particle.move());
    requestAnimationFrame(this.render.bind(this));
  }
}

export default Header;
