import React, { FunctionComponent } from "react";
import Select, { StylesConfig } from "react-select";

import { Option } from "../models/Category";

const backgroundColor = "#edf2f7";
const color = "#718096";
const borderColor = "#e7e6ff";
const indigo = "#8880ff";
const red = "#ff3c8d";

const colourStyles: StylesConfig = {
  control: (styles) => ({ ...styles, backgroundColor: "white", borderColor }),
  option: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: isFocused ? backgroundColor : null,
    color,
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: indigo,
    lineHeight: 2,
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: "white",
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: "white",
    ":hover": {
      backgroundColor: red,
      color: "white",
    },
  }),
};

interface Props {
  options: Option[];
  setCategories: React.Dispatch<React.SetStateAction<string[]>>;
}

const CategorySelect: FunctionComponent<Props> = ({ options, setCategories }) => {
  const getCategories = (selectedOptions: Option[]) => {
    // Select returns null when no options are selected.
    if (!selectedOptions) return setCategories([]);

    setCategories(selectedOptions.map((option) => option.label));
  };

  return (
    <div className="flex justify-center mb-8">
      <div className="w-10/12 md:w-4/12 max-w-4xl">
        <Select
          isMulti
          closeMenuOnSelect={false}
          onChange={getCategories}
          options={options}
          styles={colourStyles}
        />
      </div>
    </div>
  );
};

export default CategorySelect;
