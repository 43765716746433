import React, { FunctionComponent } from "react";

interface Props {
  apps: App[];
}
const freeIcon = "https://dbdjgim2rziun.cloudfront.net/free.png";

const AppList: FunctionComponent<Props> = ({ apps }) => (
  <div className="w-10/12 md:w-8/12 max-w-5xl mx-auto bg-white rounded-lg" data-cy="app-list">
    {apps.map((app) => {
      const {
        attributes: { url, title, icon, categories, description, pricingDetail, free },
      } = app;
      return (
        <a href={url} target="_blank" rel="noreferrer" key={url}>
          <div className="flex justify-between p-4 border-gray-100 border-b-2 rounded hover:bg-indigo-hover transform transition ease-in-out duration-500">
            <div className="flex justify-start">
              <img src={icon} alt={title} className="rounded-lg w-10 h-10 md:w-16 md:h-16 mr-6" />
              <div>
                <h3 className="text-indigo-accent text-lg font-bold pb-1">
                  <span className="pr-2">{title}</span>
                  {categories.map((category) => (
                    <span
                      key={category}
                      className="text-sm font-thin text-gray-600 bg-gray-200 rounded-md p-1 border border-indigo-light mr-1"
                    >
                      {category}
                    </span>
                  ))}
                </h3>
                <p className="text-gray-700 text-sm leading-relaxed">{description}</p>
                <p className="text-gray-500 text-sm leading-relaxed">{pricingDetail}</p>
              </div>
            </div>
            <div>
              {free && (
                <img src={freeIcon} alt={`${title} is a free app.`} className="w-12 md:w-8" />
              )}
            </div>
          </div>
        </a>
      );
    })}
  </div>
);

export default AppList;
