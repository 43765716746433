import React, { FormEvent, FunctionComponent, MouseEvent, useReducer, useState } from "react";

import CategoriesSlideOver from "../components/CategoriesSlideOver";
import Loading from "../components/Loading";
import { State, initialState } from "../models/Category";

type SubmissionStatus = "initial" | "loading" | "success" | "error";

interface SubscribeResponse {
  userCount: string;
}

type Following = "ALL" | "CUSTOM";

const followedCategories = (state: State, categoryNames: string[]): Following => {
  const filtered = selectedCategories(state, categoryNames);

  if (filtered.length === categoryNames.length) {
    return "ALL";
  }

  return "CUSTOM";
};

const buttonLabel = (status: SubmissionStatus) => {
  switch (status) {
    case "loading":
      return <Loading />;
    case "success":
      return "Success 🎉";
    default:
      return "Subscribe";
  }
};

const reducer = (categoryNames: string[]) => (
  state: State,
  action: { [key: string]: boolean }
): State => {
  const newState = { ...state, ...action };

  // Sort alphabetically to maintain order of inputs.
  const sorted = {} as State;

  categoryNames.forEach((category) => {
    sorted[category] = newState[category];
  });

  return sorted;
};

const selectedCategories = (state: State, categoryNames: string[]): string[] =>
  categoryNames.filter((category) => state[category]);

interface Props {
  categoryNames: string[];
}

const SignupForm: FunctionComponent<Props> = ({ categoryNames }) => {
  const [email, setEmail] = useState<string>("");
  const [numberOfUsers, setNumberOfUsers] = useState<string>(userCount);
  const [submissionStatus, setSubmissionStatus] = useState<SubmissionStatus>("initial");
  const [categories, updateCategory] = useReducer(
    reducer(categoryNames),
    initialState(categoryNames)
  );
  const [isSlideOverOpen, setIsSlideOverOpen] = useState<boolean>(false);

  const handleCollapse = (event: MouseEvent) => {
    event.preventDefault();
    setIsSlideOverOpen(true);
  };

  const handleSignUp = (event: FormEvent) => {
    event.preventDefault();

    setSubmissionStatus("loading");

    fetch("/users", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        categories:
          followedCategories(categories, categoryNames) === "ALL"
            ? []
            : selectedCategories(categories, categoryNames),
      }),
    })
      .then((response) => response.json())
      .then((data: SubscribeResponse) => {
        setSubmissionStatus("success");
        setEmail("");
        setNumberOfUsers(data.userCount);
      })
      .catch(() => setSubmissionStatus("error"));
  };

  return (
    <div className="bg-gray-200 rounded-lg p-4 sm:p-8 flex flex-col md:ml-auto w-full mt-8">
      <p className="text-gray-700 mb-5">
        Following{" "}
        <span className="text-indigo font-bold" data-cy="followed-categories">
          {followedCategories(categories, categoryNames)}
        </span>{" "}
        app categories.&nbsp;
        <a href="#" onClick={handleCollapse} className="text-indigo" data-cy="expand-categories">
          Customize {isSlideOverOpen ? <span>&rarr;</span> : <span>&larr;</span>}
        </a>
      </p>
      <form onSubmit={handleSignUp}>
        <input
          className="block w-full bg-white rounded border border-gray-400 text-gray-600 focus:outline-none focus:border-indigo-500 text-base px-4 py-2 mb-4"
          placeholder="Email"
          type="email"
          name="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
        <button
          type="submit"
          onClick={handleSignUp}
          className="flex w-full items-center justify-center text-lg font-bold px-4 py-2 border border-transparent leading-6 rounded-md text-white bg-red hover:bg-red-hover focus:outline-none active:bg-indigo-700 transition ease-in-out duration-150"
        >
          {buttonLabel(submissionStatus)}
        </button>
        <p className="text-gray-600 text-center mt-4">
          Join <span className="text-red">{numberOfUsers}</span> other Shopify merchants 👫
        </p>
        {submissionStatus === "error" && (
          <p className="text-red mt-4" data-cy="error">
            That didn&apos;t work
            <span role="img" aria-label="Sad face" className="mx-2">
              😞
            </span>
            Please try again with a valid email. If you think something is broken, please let me
            know: shopifyappsweekly@klimo.io
            <span role="img" aria-label="Thanks!" className="mx-2">
              🍻
            </span>
          </p>
        )}
      </form>

      <CategoriesSlideOver
        open={isSlideOverOpen}
        setOpen={setIsSlideOverOpen}
        categories={categories}
        categoryNames={categoryNames}
        updateCategory={updateCategory}
      />
    </div>
  );
};

export default SignupForm;
