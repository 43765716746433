export type State = { [key: string]: boolean };

export const initialState = (arr: string[]): State =>
  arr.reduce((obj: State, key: string) => {
    obj[key] = true;
    return obj;
  }, {});

export interface Option {
  value: string;
  label: string;
}

export const getOptions = (categoryNames: string[]) => {
  const options = [] as Option[];
  categoryNames.forEach((category) => options.push({ value: category, label: category }));

  return options;
};
