import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import React, { Dispatch, Fragment, FunctionComponent } from "react";
import { State } from "../models/Category";

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
  categories: State;
  updateCategory: Dispatch<{ [key: string]: boolean }>;
  categoryNames: string[];
}

const CategoriesSlideOver: FunctionComponent<Props> = ({
  open,
  setOpen,
  categories,
  categoryNames,
  updateCategory,
}) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-400 bg-opacity-50 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-base font-bold text-indigo-accent leading-6">
                          App categories
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={() => setOpen(false)}
                            data-cy="close-slide-over"
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                      <div className="pb-5" data-cy="categories">
                        {categoryNames.map((category) => (
                          <label key={category} className="block mb-2" htmlFor={category}>
                            <input
                              id={category}
                              type="checkbox"
                              name={category}
                              className="form-checkbox h-4 w-4 text-red"
                              onChange={(event) =>
                                updateCategory({ [category]: event.target.checked })
                              }
                              checked={categories[category]}
                            />
                            <span className="ml-2 text-gray-700 text-sm">{category}</span>
                          </label>
                        ))}
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CategoriesSlideOver;
