import React, { FunctionComponent, useEffect, useState } from "react";

import AppList from "../components/AppList";
import CategorySelect from "../components/CategorySelect";
import { getOptions } from "../models/Category";

interface Props {
  categoryNames: string[];
}

const LatestApps: FunctionComponent<Props> = ({ categoryNames }) => {
  const [categories, setCategories] = useState<string[]>([]);
  const [apps, setApps] = useState<App[]>([]);

  useEffect(() => {
    fetch(`/apps?categories=${encodeURI(categories.join(","))}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((json: AppResponse) => setApps(json.data));
  }, [categories]);

  return (
    <>
      <CategorySelect options={getOptions(categoryNames)} setCategories={setCategories} />
      <AppList apps={apps} />
    </>
  );
};

export default LatestApps;
